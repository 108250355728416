body {
  margin: 0;
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  font-family: "Rubik";
  height: 100%;
}

body {
  height: 100%;
}

#root {
  height: 100%;
}
/* 
@font-face {
  font-family: "Rubik";
  src: url("./Assets/font/Rubik-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("./Assets/font/Rubik-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("./Assets/font/Rubik-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("./Assets/font/Rubik-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("./Assets/font/Rubik-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Rubik";
  src: url("./Assets/font/Rubik-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Rubik";
  src: url("./Assets/font/Rubik-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Rubik";
  src: url("./Assets/font/Rubik-BoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
} */

@font-face {
  font-family: "Rubik";
  src: url("./Assets/font/Rubik-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("./Assets/font/Rubik-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("./Assets/font/Rubik-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("./Assets/font/Rubik-Bold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("./Assets/font/Rubik-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Rubik";
  src: url("./Assets/font/Rubik-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Rubik";
  src: url("./Assets/font/Rubik-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Rubik";
  src: url("./Assets/font/Rubik-BoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

/* .slide-enter {
  transform: translateY(-100%);
  transition: 0.3s cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter.slide-enter-active {
  transform: translateY(0%);
}

.slide-leave {
  transform: translateY(0%);
  transition: .3s ease-in-out;
}

.slide-leave.slide-leave-active {
  transform: translateY(-100%);
} */

.slide,
.slide.ui {
  display: block;
  margin-top: 1em;
}

.slide-enter.slide-enter-active {
  transition: opacity 300ms ease-in, margin 300ms ease-out,
    transform 0.3s cubic-bezier(0, 0.52, 0, 1);
}

.slide-leave.slide-leave-active {
  transition: opacity 300ms ease-out, margin 300ms ease-in;
}

.slide-enter,
.slide-leave.slide-leave-active {
  opacity: 0;
}

.slide-enter.slide-enter-active,
.slide-leave {
  opacity: 1;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin: 0 3px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/* subscription loader */
.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #a7a6a6;
  border-bottom-color: #808080;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
